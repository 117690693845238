import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import OrderContext from "../../../context/internal/OrderManager";
import ShopContext from "../../../context/internal/ShopManager";

function DeliveryInfoDeliveryAddressCard() {
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="deliveryAddress" />
                </h4>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="deliveryFirstName">
                                <Trans i18nKey="firstName" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="deliveryFirstName"
                                required
                                value={ order.deliveryInfo.deliveryFirstName }
                                onChange={ (event) => order.updateDeliveryInfo({ deliveryFirstName: event.target.value }) }
                                autoComplete="shipping given-name"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="deliveryLastName">
                                <Trans i18nKey="lastName" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="deliveryLastName"
                                required
                                value={ order.deliveryInfo.deliveryLastName }
                                onChange={ (event) => order.updateDeliveryInfo({ deliveryLastName: event.target.value }) }
                                autoComplete="shipping family-name"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="deliveryStreet">
                                <Trans i18nKey="street" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="deliveryStreet"
                                required
                                value={ order.deliveryInfo.deliveryStreet }
                                onChange={ (event) => order.updateDeliveryInfo({ deliveryStreet: event.target.value }) }
                                autoComplete="shipping street-address"
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="deliveryHouseNumber">
                                <Trans i18nKey="houseNumber" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="deliveryHouseNumber"
                                required
                                value={ order.deliveryInfo.deliveryHouseNumber }
                                onChange={ (event) => order.updateDeliveryInfo({ deliveryHouseNumber: event.target.value }) }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                            <label htmlFor="deliveryPostalCode">
                                <Trans i18nKey="postalCode" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="deliveryPostalCode"
                                required
                                value={ order.deliveryInfo.deliveryPostalCode }
                                onChange={ (event) => order.updateDeliveryInfo({ deliveryPostalCode: event.target.value }) }
                                autoComplete="shipping postal-code"
                            />
                        </div>
                    </div>
                    <div className="col-8">
                        <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                            <label htmlFor="deliveryCity">
                                <Trans i18nKey="city" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="deliveryCity"
                                required
                                value={ order.deliveryInfo.deliveryCity }
                                onChange={ (event) => order.updateDeliveryInfo({ deliveryCity: event.target.value }) }
                                autoComplete="shipping address-level2"
                            />
                        </div>
                    </div>
                </div>
                { shop.shop && shop.shop.shippingCosts && (
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group mb-0">
                                <label htmlFor="deliveryState">
                                    <Trans i18nKey="stateProvince" />
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="deliveryState"
                                    required
                                    value={ order.deliveryInfo.deliveryState }
                                    onChange={ (event) => order.updateDeliveryInfo({ deliveryState: event.target.value }) }
                                    autoComplete="shipping address-level1"
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group mb-0">
                                <label htmlFor="deliveryCountry">
                                    <Trans i18nKey="country" />
                                </label>
                                <select
                                    className="form-control"
                                    id="deliveryCountry"
                                    required
                                    value={ order.deliveryInfo.deliveryCountry }
                                    onChange={ (event) => order.updateDeliveryInfo({ deliveryCountry: event.target.value }) }
                                    autoComplete="shipping country"
                                >
                                    <option value="" />
                                    { order.countries.map((country) => (
                                        <option value={ country.iso } key={ country.iso }>
                                            { country.nameEnglish }
                                        </option>
                                    )) }
                                </select>
                            </div>
                        </div>
                    </div>
                ) }
            </div>
        </div>
    );
}

export default React.memo(DeliveryInfoDeliveryAddressCard);
