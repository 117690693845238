import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Trans
} from "react-i18next";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import i18next from "i18next";
import moment from "moment";

function CourseInfoLabel({ children, icon, className }) {
    return (
        <div className={ "d-flex flex-row align-items-center mr-4 " + className }>
            <i className={ icon + " mr-2" }/>
            { children }
        </div>
    );
}

function ProgressText({ progress }) {
    const className = useMemo(() => {
        let classNames = ["progress-text"];
        if(progress?.progressPercentage === 100) {
            classNames.push("progress-text-color-100");
        } else if(progress?.progressPercentage > 50) {
            classNames.push("progress-text-color-50");
        } else {
            classNames.push("progress-text-color-0");
        }
        return classNames.join(" ");
    }, [progress]);
    return (
        <OverlayTrigger
            overlay={
                <Tooltip id="progress">
                    <Trans i18nKey="courseProgressTooltip"/>
                </Tooltip>
            }
        >
            <CourseInfoLabel
                icon="fas fa-check-circle"
                className={ className }
            >
                { progress.progressPercentage }% { i18next.t("completed").toLowerCase() }
            </CourseInfoLabel>
        </OverlayTrigger>
    );
}

function AccessText({ access }) {
    if(!access) {
        return null;
    }
    if(!access.hasAccess) {
        return (
            <CourseInfoLabel
                icon="fas fa-clock"
                className="access-text access-text-color-expired"
            >
                <Trans i18nKey="accessExpired"/>
            </CourseInfoLabel>
        );
    }
    if(access.expirationDate) {
        const expirationDateMoment = moment(access.expirationDate).add(1, "day");
        const showDate = expirationDateMoment.isAfter(moment().add(7, "days"));
        return (
            <OverlayTrigger
                overlay={
                    <Tooltip id="access">
                        <Trans i18nKey="lastDayAccess"/>:
                        <br/>
                        { moment(access.expirationDate).format("DD-MM-YYYY") }
                    </Tooltip>
                }
            >
                <CourseInfoLabel
                    icon="fas fa-clock"
                    className="access-text access-text-color-active"
                >
                    { showDate ? (
                        i18next.t("accessUntil").replace("{date}", expirationDateMoment.calendar())
                    ) : (
                        i18next.t("accessFor").replace("{time}", expirationDateMoment.fromNow(true))
                    )}
                </CourseInfoLabel>
            </OverlayTrigger>
        );
    }
}

function AccountCourseCard({ course }) {
    return (
        <div className="card card-course mb-3" key={ course.id }>
            <div className="card-body">
                <h5 className="card-title">
                    { course.title }
                </h5>
                { course.description && course.description.trim().length > 0 && (
                    <p className="card-text">
                        { course.description }
                    </p>
                )}
                <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-lg-0">
                    { (!course.access || course.access.hasAccess) && (
                        <div className="mr-3 d-none d-lg-block">
                            <Link
                                to={ `/account/course/${course.id}` }
                                className="btn btn-primary"
                            >
                                <Trans i18nKey="openCourse"/>
                            </Link>
                        </div>
                    )}
                    { course.progress && (
                        <ProgressText progress={ course.progress }/>
                    )}
                    { course.access && (
                        <AccessText access={ course.access }/>
                    )}
                </div>
                { (!course.access || course.access.hasAccess) && (
                    <div className="mr-3 mt-3 d-lg-none">
                        <Link
                            to={ `/account/course/${course.id}` }
                            className="btn btn-primary"
                        >
                            <Trans i18nKey="openCourse"/>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(AccountCourseCard);
