import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import ReactPlayer from "react-player";

import VideoPlayer from "../../../../../components/VideoPlayer";
import ShopContext from "../../../../../context/internal/ShopManager";

function MarkdownLink({ node, href, children, ...linkProps }) {
    const shopContext = useContext(ShopContext);
    const currentUrl = new URL(window.location.href);
    const url = new URL(href);

    if(ReactPlayer.canPlay(href)) {
        return (
            <VideoPlayer
                url={ href }
                maxWidth={ shopContext.courseVideoMaxWidth }
                resizeWindowHeight
            />
        )
    }
    const localUrl = currentUrl.protocol === url.protocol && currentUrl.hostname === url.hostname;
    if(localUrl) {
        return (
            <Link to={ url.pathname }>
                { children }
            </Link>
        )
    }
    return (
        <a
            href={ href }
            target="_blank"
            rel="noopener noreferrer"
            { ...linkProps }
        >
            { children }
        </a>
    )
}

function CourseMarkdown({ children }) {
    return (
        <ReactMarkdown
            remarkPlugins={[gfm]}
            components={{
                a: MarkdownLink
            }}
        >
            { children }
        </ReactMarkdown>
    )
}

export default React.memo(CourseMarkdown);
