import React, {
    useMemo
} from "react";

function SelectionCard({ children, onClick, className, active }) {
    const cardClassNames = useMemo(() => {
        let list = ["card", "pointer-cursor"];
        if(active) {
            list.push("border-primary", "bg-primary-subtle");
        }
        if(className) {
            list.push(className);
        }
        return list;
    });

    return (
        <div
            className={ cardClassNames.join(" ") }
            onClick={ onClick }
        >
            <div className="card-body">
                { children }
            </div>
        </div>
    );
}

export default React.memo(SelectionCard);
