import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import RentalContext from "../../../context/internal/RentalManager";

function RentalInfoCustomerCard() {
    const rental = useContext(RentalContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="customer" />
                </h4>
                <div className="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        className="custom-control-input"
                        id="private"
                        name="company"
                        checked={ !rental.rentalInfo.company }
                        onChange={ (event) => rental.updateRentalInfo({ company: !event.target.checked }) }
                    />
                    <label className="custom-control-label" htmlFor="private">
                        <Trans i18nKey="customerPersonal" />
                    </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        className="custom-control-input" id="company"
                        name="company"
                        checked={ rental.rentalInfo.company }
                        onChange={ (event) => rental.updateRentalInfo({ company: event.target.checked }) }
                    />
                    <label className="custom-control-label" htmlFor="company">
                        <Trans i18nKey="customerCompany" />
                    </label>
                </div>
                { rental.rentalInfo.company && (
                    <div className="form-group">
                        <label htmlFor="companyName">
                            <Trans i18nKey="companyName" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            required
                            value={ rental.rentalInfo.companyName }
                            onChange={ (event) => rental.updateRentalInfo({ companyName: event.target.value }) }
                            autoComplete="organization"
                        />
                    </div>
                ) }
            </div>
        </div>
    );
}

export default React.memo(RentalInfoCustomerCard);
