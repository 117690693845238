import React, {
    useContext,
    useMemo,
    useState
} from "react";
import i18next from "i18next";
import {
    Link,
    Redirect
} from "react-router-dom";
import {
    Alert,
    Spinner
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import AuthenticationContext from "../../context/internal/AuthenticationManager";
import Loading from "../../components/Loading";

function LoginPageContent({ urlAfterLogin = "/account", urlRegister = "/register" }) {
    const authentication = useContext(AuthenticationContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
        }
    }, []);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            authentication.login(email, password, onError, urlAfterLogin);
        }
    }, [authentication, email, password, onError, urlAfterLogin]);

    const authenticationStatus = useMemo(() => {
        return authentication.getAuthenticationStatus();
    }, [authentication]);

    if(authenticationStatus === "unsupported") {
        return (
            <Alert variant="danger">
                <Trans i18nKey="errorAuthenticationUnsupported"/>
            </Alert>
        )
    }
    if(authenticationStatus === "unknown") {
        return (
            <Loading/>
        );
    }
    if(authenticationStatus === "loggedin") {
        return (
            <Redirect to={ urlAfterLogin }/>
        )
    }
    return (
        <div
            className="py-4"
            style={{ maxWidth: "350px", marginLeft: "auto", marginRight: "auto" }}
        >
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}

            <form onSubmit={ onSubmit }>
                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={ i18next.t("email") }
                        disabled={ authentication.loading }
                        value={ email }
                        onChange={(event) => setEmail(event.target.value) }
                        autoComplete="email"
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder={ i18next.t("password") }
                        disabled={ authentication.loading }
                        value={ password }
                        onChange={ (event) => setPassword(event.target.value) }
                        autoComplete="current-password"
                    />
                </div>
                <div className="d-flex align-items-center flex-column">
                    <Link to="/forgot-password" className="btn btn-link mb-2">
                        <Trans i18nKey="forgotPassword"/>
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-primary px-5"
                        disabled={ authentication.loading }
                    >
                        { authentication.loading ? (
                            <Spinner animation="border" variant="dark" size="sm"/>
                        ) : (
                            <Trans i18nKey="login"/>
                        )}
                    </button>
                </div>
            </form>
            <div className="mt-4 d-flex justify-content-center">
                <div>
                    <Trans i18nKey="notYetHaveAccount"/>
                    {" "}
                    <Link to={ urlRegister }>
                        <Trans i18nKey="notYetHaveAccountButton"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default React.memo(LoginPageContent);
