import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import OrderContext from "../../../context/internal/OrderManager";
import AuthenticationContext from "../../../context/internal/AuthenticationManager";

function DeliveryInfoContactCard() {
    const order = useContext(OrderContext);
    const authentication = useContext(AuthenticationContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="contactInformation" />
                </h4>
                <div className="form-group">
                    <label htmlFor="email">
                        <Trans i18nKey="email" />
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        value={ order.deliveryInfo.email }
                        disabled={ authentication.user }
                        onChange={ (event) => order.updateDeliveryInfo({ email: event.target.value }) }
                        autoComplete="email"
                    />
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="phoneNumber">
                        <Trans i18nKey="phoneNumber" />
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        required
                        value={ order.deliveryInfo.phoneNumber }
                        onChange={ (event) => order.updateDeliveryInfo({ phoneNumber: event.target.value }) }
                        autoComplete="tel"
                    />
                </div>
            </div>
        </div>
    );
}

export default React.memo(DeliveryInfoContactCard);
