import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import {
    useHistory,
    useParams
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";
import Skeleton from "react-loading-skeleton";

import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import Loading from "../../components/Loading";
import RentalContext from "../../context/internal/RentalManager";
import PriceFormatter from "../../components/PriceFormatter";
import RentalDateRangePicker from "../../components/date-picker/RentalDateRangePicker";

function RentalDatePageContent({ page404 }) {
    const rentalContext = useContext(RentalContext);
    const { productId } = useParams();
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        rentalContext.setCurrentProduct(productId);
    }, [rentalContext, productId]);

    const startDateMoment = useMemo(() => {
        return rentalContext.rentalInfo.startDate ? moment(rentalContext.rentalInfo.startDate) : null;
    }, [rentalContext.rentalInfo.startDate]);
    const endDateMoment = useMemo(() => {
        return rentalContext.rentalInfo.endDate ? moment(rentalContext.rentalInfo.endDate) : null;
    }, [rentalContext.rentalInfo.endDate]);

    const onSubmit = useCallback(() => {
        if(!startDateMoment || !endDateMoment || startDateMoment > endDateMoment) {
            setError("Ongeldige start en/of einddatum ingevuld.");
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(rentalContext.getAmountOfDaysSelected() > 28) {
            setError("Huur duur is langer dan 28 dagen. Neem contact op om langer dan 28 dagen te huren.");
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(!rentalContext.isDateRangeAvailable(startDateMoment, endDateMoment)) {
            setError("Het product is al verhuurd op 1 van de geselecteerde dagen.");
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        rentalContext.updateRentalInfo({
            startDate: startDateMoment.format("YYYY-MM-DD"),
            endDate: endDateMoment.format("YYYY-MM-DD")
        });
        history.push("/rental-order/" + rentalContext.product.id + "/data");
    }, [rentalContext, startDateMoment, endDateMoment, history]);

    if(rentalContext.error) {
        if(rentalContext.errorCode === "DOESNT_EXIST" && page404) {
            return page404;
        }
        return (
            <Alert variant="danger">{ rentalContext.error }</Alert>
        );
    }
    if(!rentalContext.product || !rentalContext.availableDates) {
        return (
            <Loading/>
        );
    }
    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="product"/> } to={ "/rental/" + productId } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="date"/> } to={ "/rental-order/" + productId + "/date" } active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to={ "/rental-order/" + productId + "/data" }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to={ "/rental-order/" + productId + "/paymentmethod" }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to={ "/rental-order/" + productId + "/overview" }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>

            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { rentalContext.priceError && (
                <Alert variant="danger">{ rentalContext.priceError }</Alert>
            )}

            <div className="d-flex justify-content-center">
                <RentalDateRangePicker/>
            </div>

            <div className="card mb-3 mt-3">
                <div className="card-body">
                    { (!startDateMoment || !endDateMoment) ? (
                        <p className="card-text">
                            <Trans i18nKey="rentalSelectDates"/>
                        </p>
                    ) : rentalContext.price === null ? (
                        <React.Fragment>
                            <p className="card-text">
                                <Skeleton/>
                            </p>
                            <p className="card-text">
                                <Skeleton/>
                            </p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <p className="card-text">
                                { rentalContext.getAmountOfDaysSelected() === 1 ? (
                                    <React.Fragment>
                                        { i18next.t("rentalDateSelected").replace("{date}", startDateMoment.format("dddd LL")) }
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        { i18next.t("rentalDatesSelected")
                                            .replace("{startDate}", startDateMoment.format("dddd LL"))
                                            .replace("{endDate}", endDateMoment.format("dddd LL")) }
                                    </React.Fragment>
                                )}
                            </p>
                            <p className="card-text">
                                <b>
                                    { rentalContext.getAmountOfDaysSelected() }{" "}
                                    { i18next.t(rentalContext.getAmountOfDaysSelected() === 1 ? "day" : "days").toLowerCase() }:{" "}
                                    <PriceFormatter price={ rentalContext.price }/>
                                </b>
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </div>

            <div className="card mb-3 mt-3">
                <div className="card-body">
                    <p className="card-text">
                        <Trans i18nKey="rentalContact28Days"/>
                    </p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="float-right">
                        <Button onClick={ onSubmit } variant="success">
                            <Trans i18nKey="nextStep"/>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RentalDatePageContent;
