import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";

import OrderContext from "../../../context/internal/OrderManager";
import ShopContext from "../../../context/internal/ShopManager";

function OrderOverviewDeliveryInfoCard() {
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);

    const deliveryCountry = !order.countries ? null : order.countries.find((country) => {
        return country.iso === order.deliveryInfo.deliveryCountry;
    });

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    { order.deliveryInfo.invoiceEqualsDelivery ? (
                        <Trans i18nKey="deliveryInvoiceAddress" />
                    ) : (
                        <Trans i18nKey="deliveryAddress" />
                    ) }
                    <Link to="/order/data" className="btn btn-link btn-sm">
                        <Trans i18nKey="edit" />
                    </Link>
                </h5>
                <p className="card-text">
                    { order.deliveryInfo.deliveryFirstName + " " + order.deliveryInfo.deliveryLastName }
                    <br />
                    { order.deliveryInfo.deliveryStreet + " " + order.deliveryInfo.deliveryHouseNumber }
                    <br />
                    { order.deliveryInfo.deliveryPostalCode + ", " + order.deliveryInfo.deliveryCity }
                    { shop.shop.shippingCosts && (
                        <React.Fragment>
                            <br />
                            { order.deliveryInfo.deliveryState + ", " + (deliveryCountry ? deliveryCountry.nameEnglish : "Invalid country") }
                        </React.Fragment>
                    ) }
                </p>
            </div>
        </div>
    );
}

export default React.memo(OrderOverviewDeliveryInfoCard);
