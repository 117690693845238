import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import ProductImage from "../ProductImage";
import PriceDisplay from "../PriceDisplay";
import CartContext from "../../context/internal/CartManager";

function AddedToCartModal(props) {
    const cartContext = useContext(CartContext);
    return (
        <Modal show={ props.show } onHide={ props.handleClose } size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    { cartContext.error ? (
                        <Trans i18nKey="error"/>
                    ) : (
                        <Trans i18nKey="addedToCart"/>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { cartContext.error ? (
                    <div className="d-flex flex-column justify-content-center">
                        <Alert variant="danger">{ cartContext.error }</Alert>
                        <div className="d-flex w-100 justify-content-center">
                            <button onClick={ props.handleClose } className="btn btn-secondary mt-2">
                                <Trans i18nKey="close"/>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-lg-3 col-sm-4 mb-3 mb-lg-0">
                            <div
                                className="d-flex justify-content-center align-content-center rounded"
                                style={{ height: "120px", backgroundColor: "#f5f5f5" }}
                            >
                                <ProductImage
                                    image={ props.product?.images?.[0] }
                                    size="175x130"
                                    className="img-fluid rounded"
                                    alt="Product"
                                    style={{
                                        maxHeight: "120px",
                                        objectFit: "contain"
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-8 mb-3 mb-md-0 d-flex flex-row">
                            <div className="flex-grow-1">
                                <h4>{ props.product.name }</h4>
                                { props.product.subtitle.length > 0 && (
                                    <h6 className="text-muted">{ props.product.subtitle }</h6>
                                )}
                            </div>
                            <div className="text-right ml-2">
                                <PriceDisplay product={ props.product } stacked/>
                            </div>
                        </div>
                        <div className="col-lg-3 d-flex flex-column flex-sm-row flex-lg-column align-items-stretch" style={{ gap: ".5rem" }}>
                            <div className="flex-grow-1 flex-basis-0">
                                <Link to="/cart" className="btn btn-success px-4 py-2 w-100">
                                    <i className="fas fa-shopping-cart mr-2"/>
                                    <Trans i18nKey="toOrder"/>
                                </Link>
                            </div>
                            <div className="flex-grow-1 flex-basis-0">
                                <Button
                                    variant="secondary"
                                    className="px-4 py-2 w-100"
                                    onClick={ props.handleClose }
                                >
                                    <Trans i18nKey="continueShopping"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AddedToCartModal);
