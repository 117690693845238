import React from "react";
import i18next from "i18next";

import shopcrateApi, {
    createAxios,
    SHOPCRATE_API_SUB
} from "../../ShopCrateAPI";

const ShopContext = React.createContext(null);

export class ShopManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: null,
            error: null,
            shopApi: this.getShopAPIAxios(),

            // TODO: Find a more elegant way to do this.
            courseVideoMaxWidth: 640,
            setCourseVideoMaxWidth: this.setCourseVideoMaxWidth.bind(this)
        }
    }

    componentDidMount() {
        this.getShop();
    }

    setCourseVideoMaxWidth(maxWidth) {
        this.setState({ courseVideoMaxWidth: maxWidth });
    }

    getShopAPIAxios(shop) {
        if(!shop || !shop.authenticationSupported || !shop.apiUrl) {
            return null;
        }
        if(process.env.REACT_APP_ENVIRONMENT === "development") {
            // When running in development, use locally hosted ShopCrate API.
            console.log("[ShopManager] Running in development, using locally hosted ShopCrate API.");
            return shopcrateApi;
        }
        const currentHost = window.location.host;
        if(currentHost.endsWith("shopcrate.nl")) {
            // When webshop is running on shopcrate.nl, use the main ShopCrate API.
            console.log("[ShopManager] Running on shopcrate.nl, using main ShopCrate API.");
            return shopcrateApi;
        }
        const baseUrl = shop.apiUrl + SHOPCRATE_API_SUB;
        return createAxios(baseUrl);
    }

    getShop() {
        shopcrateApi.get("/getShop")
            .then((response) => {
                if(response.data.valid) {
                    const shop = response.data.shop;
                    this.setState({
                        shop,
                        shopApi: this.getShopAPIAxios(shop)
                    });
                } else {
                    this.setState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: i18next.t("errorGeneral") });
            });
    }

    render() {
        return (
            <ShopContext.Provider value={ this.state }>
                {this.props.children}
            </ShopContext.Provider>
        )
    }
}

export function withShopContext(Component) {
    return function contextComponent(props) {
        return (
            <ShopContext.Consumer>
                {context => <Component {...props} shopContext={context} />}
            </ShopContext.Consumer>
        )
    }
}

export default ShopContext;
