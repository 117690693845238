import React, {
    useContext,
    useEffect
} from "react";
import {
    Trans
} from "react-i18next";

import RentalContext from "../../../context/internal/RentalManager";
import AuthenticationContext from "../../../context/internal/AuthenticationManager";

function RentalInfoContactCard() {
    const rental = useContext(RentalContext);
    const authentication = useContext(AuthenticationContext);

    useEffect(() => {
        if(!authentication.user) {
            return;
        }
        if(rental.rentalInfo.email === authentication.user.email) {
            return;
        }
        rental.updateRentalInfo({
            email: authentication.user.email
        });
    }, [rental, authentication]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="contactInformation" />
                </h4>
                <div className="form-group">
                    <label htmlFor="email">
                        <Trans i18nKey="email" />
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        value={ rental.rentalInfo.email }
                        disabled={ authentication.user }
                        onChange={ (event) => rental.updateRentalInfo({ email: event.target.value }) }
                        autoComplete="email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">
                        <Trans i18nKey="phoneNumber" />
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        required
                        placeholder="06 12345678"
                        value={ rental.rentalInfo.phoneNumber }
                        onChange={ (event) => rental.updateRentalInfo({ phoneNumber: event.target.value }) }
                        autoComplete="tel"
                    />
                </div>
            </div>
        </div>
    );
}

export default React.memo(RentalInfoContactCard);
