import React, {
    useMemo
} from "react";
import {
    Route,
    useRouteMatch
} from "react-router-dom";
import i18next from "i18next";

import DefaultLayout from "./DefaultLayout";
import DefaultCheckoutLayout from "./DefaultCheckoutLayout";
import DefaultAccountLayout from "./DefaultAccountLayout";
import DefaultProductLayout from "./DefaultProductLayout";
import DefaultRentalLayout from "./DefaultRentalLayout";
import DefaultCategoryLayout from "./DefaultCategoryLayout";

import CartPageContent from "../page-content/order/CartPageContent";
import AccountSelectionPageContent from "../page-content/order/AccountSelectionPageContent";
import OrderLoginPageContent from "../page-content/order/OrderLoginPageContent";
import OrderRegisterPageContent from "../page-content/order/OrderRegisterPageContent";
import DeliveryInfoPageContent from "../page-content/order/DeliveryInfoPageContent";
import PaymentMethodSelectionPageContent from "../page-content/order/PaymentMethodSelectionPageContent";
import OrderOverviewPageContent from "../page-content/order/OrderOverviewPageContent";
import OrderStatusPageContent from "../page-content/order/OrderStatusPageContent";
import DigitalProductDemoPageContent from "../page-content/DigitalProductDemoPageContent";
import CourseDemoPageContent from "../page-content/CourseDemoPageContent";
import LoginPageContent from "../page-content/account/LoginPageContent";
import RegisterPageContent from "../page-content/account/RegisterPageContent";
import ForgotPasswordPageContent from "../page-content/account/ForgotPasswordPageContent";
import ResetPasswordPageContent from "../page-content/account/ResetPasswordPageContent";
import AccountPageContent from "../page-content/account/AccountPageContent";
import RentalDatePageContent from "../page-content/rental/RentalDatePageContent";
import RentalInfoPageContent from "../page-content/rental/RentalInfoPageContent";
import RentalOrderOverviewPageContent from "../page-content/rental/RentalOrderOverviewPageContent";
import RentalOrderStatusPageContent from "../page-content/rental/RentalOrderStatusPageContent";

const defaultLayouts = {
    regular: DefaultLayout,
    checkout: DefaultCheckoutLayout,
    account: DefaultAccountLayout,
    product: DefaultProductLayout,
    rental: DefaultRentalLayout,
    category: DefaultCategoryLayout
}

function useShopCrateRoutes(layouts = defaultLayouts) {
    const finalLayouts = useMemo(() => {
        return { ...defaultLayouts, ...layouts };
    }, [layouts]);

    const isAccountCourse = useRouteMatch({ path: "/account/course" });

    const RegularLayout = finalLayouts.regular;
    const CheckoutLayout = finalLayouts.checkout;
    const AccountLayout = finalLayouts.account;

    return [
        <Route path={[
            "/product/:productId",
            "/product/:productId/:productUrl"
        ]} exact component={finalLayouts.product} key="product"/>,
        <Route path={[
            "/rental/:productId",
            "/rental/:productId/:productUrl"
        ]} exact component={finalLayouts.rental} key="rental"/>,
        <Route path={[
            "/category/:categoryId",
            "/category/:categoryId/:categoryUrl"
        ]} exact component={finalLayouts.category} key="category"/>,

        <Route path="/cart" exact key="cart">
            <CheckoutLayout title={ i18next.t("cart") }>
                <CartPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/account-selection" exact key="order/account-selection">
            <CheckoutLayout title={ i18next.t("data") }>
                <AccountSelectionPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/login" exact key="order/login">
            <CheckoutLayout title={ i18next.t("data") }>
                <OrderLoginPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/register" exact key="order/register">
            <CheckoutLayout title={ i18next.t("data") }>
                <OrderRegisterPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/data" exact key="order/data">
            <CheckoutLayout title={ i18next.t("data") }>
                <DeliveryInfoPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/paymentmethod" exact key="order/paymentmethod">
            <CheckoutLayout title={ i18next.t("paymentMethod") }>
                <PaymentMethodSelectionPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/overview" exact key="order/overview">
            <CheckoutLayout title={ i18next.t("overview") }>
                <OrderOverviewPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/order/:orderToken" exact key="order/status">
            <CheckoutLayout title={ i18next.t("order") }>
                <OrderStatusPageContent/>
            </CheckoutLayout>
        </Route>,

        <Route path="/rental-order/:productId/date" exact key="rental-order/date">
            <CheckoutLayout title={ i18next.t("dateSelection") }>
                <RentalDatePageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/rental-order/:productId/data" exact key="rental-order/data">
            <CheckoutLayout title={ i18next.t("data") }>
                <RentalInfoPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/rental-order/:productId/paymentmethod" exact key="rental-order/paymentmethod">
            <CheckoutLayout title={ i18next.t("paymentMethod") }>
                <PaymentMethodSelectionPageContent type="rental"/>
            </CheckoutLayout>
        </Route>,
        <Route path="/rental-order/:productId/overview" exact key="rental-order/overview">
            <CheckoutLayout title={ i18next.t("overview") }>
                <RentalOrderOverviewPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route path="/rental-order/complete/:orderToken" exact key="rental-order/complete">
            <CheckoutLayout title={ i18next.t("rentalConfirmation") }>
                <RentalOrderStatusPageContent/>
            </CheckoutLayout>
        </Route>,

        <Route path="/digital-product-demo/:token" exact key="digital-product-demo">
            <CheckoutLayout title={ i18next.t("demo") }>
                <DigitalProductDemoPageContent/>
            </CheckoutLayout>
        </Route>,
        <Route
            path={ ["/course-demo/:courseToken", "/course-demo/:courseToken/lesson/:lessonId"] }
            exact
            key="course-demo"
        >
            <CourseDemoPageContent/>
        </Route>,

        <Route path="/login" exact key="login">
            <RegularLayout title={ i18next.t("login") }>
                <LoginPageContent/>
            </RegularLayout>
        </Route>,
        <Route path="/register" exact key="register">
            <RegularLayout title={ i18next.t("register") }>
                <RegisterPageContent/>
            </RegularLayout>
        </Route>,
        <Route path="/forgot-password" exact key="forgot-password">
            <RegularLayout title={ i18next.t("forgotPassword") }>
                <ForgotPasswordPageContent/>
            </RegularLayout>
        </Route>,
        <Route path="/reset-password/:token" exact key="reset-password">
            <RegularLayout title={ i18next.t("resetPassword") }>
                <ResetPasswordPageContent/>
            </RegularLayout>
        </Route>,
        <Route path="/account" key="account">
            { isAccountCourse ? (
                <AccountPageContent/> // No layout when user is in a course.
            ) : (
                <AccountLayout title={ i18next.t("account") }>
                    <AccountPageContent/>
                </AccountLayout>
            )}
        </Route>,
    ]
}

export default useShopCrateRoutes;
