import React from "react";
import {
    Trans
} from "react-i18next";

import ProductImage from "../ProductImage";
import PriceFormatter from "../PriceFormatter";
import PriceDisplay from "../PriceDisplay";

function OrderProductCard({ product }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex w-100 flex-md-row flex-column">
                    <div
                        className="d-flex justify-content-center align-content-center align-self-center rounded mr-md-3 mb-3 mb-md-0 h-100"
                        style={{ height: "120px", backgroundColor: "white" }}
                    >
                        <div style={{ width: "175px" }}>
                            <ProductImage
                                image={ product ? product.images[0] : null }
                                size="175x130"
                                className="img-fluid rounded"
                                alt="Product"
                            />
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            { product.name }
                            { product.type?.value === "digital" && (
                                <small>
                                    <span className="badge badge-pill badge-primary ml-2">
                                        <Trans i18nKey="digital"/>
                                    </span>
                                </small>
                            )}
                            { product.type?.value === "course" && (
                                <small>
                                    <span className="badge badge-pill badge-primary ml-2">
                                        <Trans i18nKey="course"/>
                                    </span>
                                </small>
                            )}
                        </h5>
                        <div className="d-flex flex-row">
                            <div className="flex-grow-1">
                                <b className="text-muted">
                                    { product.amount }x
                                    {" "}
                                    <PriceFormatter price={ product.orderPrice }/>
                                </b>
                            </div>
                            <div>
                                <PriceDisplay
                                    product={ product }
                                    amount={ product.amount }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(OrderProductCard);
