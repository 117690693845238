import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import i18next from "i18next";
import {
    Button
} from "react-bootstrap";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import CartContext from "../../context/internal/CartManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductPageAddToCartButton({ buttonVariant = "success" }) {
    const productContext = useContext(ProductPageContext);
    const cartContext = useContext(CartContext);
    const shopContext = useContext(ShopContext);
    const [loading, setLoading] = useState(false);

    const buttonText = useMemo(() => {
        if(!productContext.product || !shopContext.shop) {
            return null;
        }
        if(!shopContext.shop.orderingEnabled) {
            return i18next.t("contactUs");
        }
        if(!productContext.product.orderable) {
            return i18next.t(productContext.product.digital ? "notAvailable" : "soldOut");
        }
        return i18next.t("addToCart");
    }, [productContext.product, shopContext.shop]);
    const buttonIcon = useMemo(() => {
        if(!productContext.product || !shopContext.shop) {
            return null;
        }
        if(loading) {
            return (
                <i className="fas fa-circle-notch fa-spin mr-2"/>
            )
        }
        if(!shopContext.shop.orderingEnabled) {
            return (
                <i className="fas fa-phone mr-2"/>
            );
        }
        if(!productContext.product.orderable) {
            return (
                <i className="fas fa-shopping-cart mr-2"/>
            );
        }
        return (
            <i className="fas fa-cart-arrow-down mr-2"/>
        );
    }, [loading, productContext, shopContext]);

    const onClick = useMemo(() => {
        return async () => {
            if(!shopContext.shop || !shopContext.shop.orderingEnabled || !productContext.product.orderable) {
                return;
            }
            setLoading(true);
            await cartContext.addProductToCart(productContext.product.id, 1);
            setLoading(false);
            productContext.triggerAddedToCartModal();
        }
    }, [shopContext.shop, cartContext, productContext]);

    if(!productContext.product || !shopContext.shop) {
        return (
            <Skeleton height={ 42 } width={ 185 }/>
        )
    }
    if(!shopContext.shop.orderingEnabled) {
        return (
            <Link
                to="/contact"
                className={ "btn btn-" + buttonVariant + " product-page-add-to-cart-button" }
                disabled
            >
                { buttonIcon }
                { buttonText }
            </Link>
        )
    }
    return (
        <Button
            variant={ buttonVariant }
            className="product-page-add-to-cart-button"
            onClick={ onClick }
            disabled={ !productContext.product.orderable || loading }
        >
            { buttonIcon }
            { buttonText }
        </Button>
    )
}

export default React.memo(ProductPageAddToCartButton);
