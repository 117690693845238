import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";
import Calendar from "react-calendar";

import useWindowSize from "../../hooks/WindowSize";

function DateRangePicker({ startDate, endDate, onChange, minDate, maxDate, tileDisabled }) {
    const windowSize = useWindowSize();
    const [numberOfMonths, setNumberOfMonths] = useState(2);

    useEffect(() => {
        if(windowSize.width < 768) {
            setNumberOfMonths(1);
        } else {
            setNumberOfMonths(2);
        }
    }, [windowSize]);

    return (
        <div>
            <Calendar
                // calendarType="gregory"
                showDoubleView={ numberOfMonths >= 2 }
                showNeighboringMonth={ false }
                selectRange
                allowPartialRange
                goToRangeStartOnSelect={ false }

                minDate={ minDate }
                maxDate={ maxDate }
                tileDisabled={ tileDisabled }

                value={ [startDate, endDate] }
                onChange={ onChange }

                prevLabel={ <i className="fas fa-chevron-left fa-fw"/> }
                nextLabel={ <i className="fas fa-chevron-right fa-fw"/> }
                prev2Label={ <i className="fas fa-chevrons-left fa-fw"/> }
                next2Label={ <i className="fas fa-chevrons-right fa-fw"/> }
                minDetail="year"
            />
        </div>
    );
}

export default React.memo(DateRangePicker);
