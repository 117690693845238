import React from "react";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import PriceFormatter from "../PriceFormatter";
import PriceSummaryRow from "../PriceSummaryRow";

function OrderPriceSummary({ order }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <PriceSummaryRow
                    title={ i18next.t("shippingCosts") }
                    className="mb-2"
                >
                    { order.shippingPrice === null ? (
                        <Trans i18nKey="selectCountry"/>
                    ) : order.shippingPrice === 0 ? (
                        <Trans i18nKey="free"/>
                    ) : (
                        <PriceFormatter price={ order.shippingPrice }/>
                    ) }
                </PriceSummaryRow>
                <PriceSummaryRow
                    title={ i18next.t("vat") }
                    className="mb-2 text-muted"
                >
                    <PriceFormatter price={ order.totalTaxPrice }/>
                </PriceSummaryRow>
                { order.discountAmountInclVat > 0 && (
                    <PriceSummaryRow
                        title={ i18next.t("discount") }
                        className="mb-2 text-muted"
                    >
                        <PriceFormatter price={ order.discountAmountInclVat }/>
                    </PriceSummaryRow>
                )}
                { (order.originalPriceInclVat !== null && order.originalPriceInclVat !== order.totalPrice) ? (
                    <React.Fragment>
                        <PriceSummaryRow
                            title={ i18next.t("originally") }
                            className="text-danger"
                            style={{
                                textDecoration: "line-through"
                            }}
                        >
                            <PriceFormatter price={ order.originalPriceInclVat }/>
                        </PriceSummaryRow>
                        <PriceSummaryRow
                            title={ i18next.t("totalPrice") }
                            subtitle={ i18next.t("includingVat") }
                        >
                            <PriceFormatter price={ order.totalPrice }/>
                        </PriceSummaryRow>
                    </React.Fragment>
                ) : (
                    <PriceSummaryRow
                        title={ i18next.t("totalPrice") }
                        subtitle={ i18next.t("includingVat") }
                    >
                        <PriceFormatter price={ order.totalPrice }/>
                    </PriceSummaryRow>
                )}
            </div>
        </div>
    )
}

export default React.memo(OrderPriceSummary);
