import React, {
    useCallback,
    useContext
} from "react";
import moment from "moment";

import RentalContext from "../../context/internal/RentalManager";
import DateRangePicker from "./DateRangePicker";

function RentalDateRangePicker() {
    const rentalContext = useContext(RentalContext);

    const onChange = useCallback((dateRange) => {
        const startDate = dateRange[0];
        const endDate = dateRange[1];
        const startDateFormat = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
        const endDateFormat = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
        rentalContext.updateRentalInfo({
            startDate: startDateFormat,
            endDate: endDateFormat
        });
    }, [rentalContext]);

    const tileDisabled = useCallback(({ date }) => {
        const momentDate = moment(date);
        const dayAvailable = rentalContext.isDayAvailable(momentDate);
        if(!dayAvailable) {
            return true;
        }
        if(rentalContext.rentalInfo.startDate && !rentalContext.rentalInfo.endDate) {
            // Only start date is selected, so user is currently selecting endDate.
            const startDate = moment(rentalContext.rentalInfo.startDate);

            // Block date if it's 28 days after the startDate.
            if(momentDate.isAfter(startDate.clone().add(27, "days"))) {
                return true;
            }

            // Block date if between the startDate and the date currently being checked, lies a date that isn't available.
            if(!rentalContext.isDateRangeAvailable(startDate, momentDate)) {
                return true;
            }
        }
        return false;
    }, [rentalContext]);

    return (
        <DateRangePicker
            startDate={ rentalContext.rentalInfo?.startDate }
            endDate={ rentalContext.rentalInfo?.endDate }
            onChange={ onChange }
            minDate={ moment().add(1, "day").toDate() }
            tileDisabled={ tileDisabled }
        />
    );
}

export default React.memo(RentalDateRangePicker);
