import React, {
    useContext,
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";

import ShopContext from "../../../context/internal/ShopManager";
import OrderContext from "../../../context/internal/OrderManager";

function DeliveryInfoInvoiceAddressCard() {
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);

    const deliveryCountry = useMemo(() => {
        if(!order.countries) {
            return null;
        }
        return order.countries.find((country) => {
            return country.iso === order.deliveryInfo.deliveryCountry
        });
    }, [order.countries, order.deliveryInfo.deliveryCountry]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="invoiceAddress" />
                </h4>
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="invoiceEqualsDelivery"
                        checked={ order.deliveryInfo.invoiceEqualsDelivery }
                        onChange={ (event) => order.updateDeliveryInfo({ invoiceEqualsDelivery: event.target.checked }) }
                    />
                    <label className="custom-control-label" htmlFor="invoiceEqualsDelivery">
                        <Trans i18nKey="invoiceEqualsDelivery" />
                    </label>
                </div>
                { order.deliveryInfo.invoiceEqualsDelivery ? (
                    order.deliveryInfo.deliveryStreet && order.deliveryInfo.deliveryHouseNumber
                    && order.deliveryInfo.deliveryPostalCode && order.deliveryInfo.deliveryCity
                    && (!shop.shop.shippingCosts || (order.deliveryInfo.deliveryState && order.deliveryInfo.deliveryCountry)) && (
                        <React.Fragment>
                            <h6 className="mb-0 mt-2"><Trans i18nKey="address" /></h6>
                            <p className="mb-0">
                                { order.deliveryInfo.deliveryStreet + " " + order.deliveryInfo.deliveryHouseNumber }
                                <br />
                                { order.deliveryInfo.deliveryPostalCode + " " + order.deliveryInfo.deliveryCity }
                                { shop.shop.shippingCosts && (
                                    <React.Fragment>
                                        <br />
                                        { order.deliveryInfo.deliveryState + ", " + (deliveryCountry ? deliveryCountry.nameEnglish : "Invalid country") }
                                    </React.Fragment>
                                ) }
                            </p>
                        </React.Fragment>
                    )
                ) : (
                    <React.Fragment>
                        <div className="row mt-2">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="invoiceFirstName">
                                        <Trans i18nKey="firstName" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="invoiceFirstName"
                                        required
                                        value={ order.deliveryInfo.invoiceFirstName }
                                        onChange={ (event) => order.updateDeliveryInfo({ invoiceFirstName: event.target.value }) }
                                        autoComplete="billing given-name"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="invoiceLastName">
                                        <Trans i18nKey="lastName" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="invoiceLastName"
                                        required
                                        value={ order.deliveryInfo.invoiceLastName }
                                        onChange={ (event) => order.updateDeliveryInfo({ invoiceLastName: event.target.value }) }
                                        autoComplete="billing family-name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="invoiceStreet">
                                        <Trans i18nKey="street" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="invoiceStreet"
                                        required
                                        value={ order.deliveryInfo.invoiceStreet }
                                        onChange={ (event) => order.updateDeliveryInfo({ invoiceStreet: event.target.value }) }
                                        autoComplete="billing street-address"
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="invoiceHouseNumber">
                                        <Trans i18nKey="houseNumber" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="invoiceHouseNumber"
                                        required
                                        value={ order.deliveryInfo.invoiceHouseNumber }
                                        onChange={ (event) => order.updateDeliveryInfo({ invoiceHouseNumber: event.target.value }) }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                                    <label htmlFor="invoicePostalCode">
                                        <Trans i18nKey="postalCode" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="invoicePostalCode"
                                        required
                                        value={ order.deliveryInfo.invoicePostalCode }
                                        onChange={ (event) => order.updateDeliveryInfo({ invoicePostalCode: event.target.value }) }
                                        autoComplete="billing postal-code"
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <div className={ "form-group" + (shop.shop && shop.shop.shippingCosts ? "" : " mb-0") }>
                                    <label htmlFor="invoiceCity">
                                        <Trans i18nKey="city" />
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="invoiceCity"
                                        required
                                        value={ order.deliveryInfo.invoiceCity }
                                        onChange={ (event) => order.updateDeliveryInfo({ invoiceCity: event.target.value }) }
                                        autoComplete="billing address-level2"
                                    />
                                </div>
                            </div>
                        </div>
                        { shop.shop && shop.shop.shippingCosts && (
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-0">
                                        <label htmlFor="invoiceState">
                                            <Trans i18nKey="stateProvince" />
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="invoiceState"
                                            required
                                            value={ order.deliveryInfo.invoiceState }
                                            onChange={ (event) => order.updateDeliveryInfo({ invoiceState: event.target.value }) }
                                            autoComplete="billing address-level1"
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-0">
                                        <label htmlFor="invoiceCountry">
                                            <Trans i18nKey="country" />
                                        </label>
                                        <select
                                            className="form-control"
                                            id="invoiceCountry"
                                            required
                                            value={ order.deliveryInfo.invoiceCountry }
                                            onChange={ (event) => order.updateDeliveryInfo({ invoiceCountry: event.target.value }) }
                                            autoComplete="billing country"
                                        >
                                            <option value="" />
                                            { order.countries.map((country) => (
                                                <option value={ country.iso } key={ country.iso }>
                                                    { country.nameEnglish }
                                                </option>
                                            )) }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ) }
                    </React.Fragment>
                ) }
            </div>
        </div>
    );
}

export default React.memo(DeliveryInfoInvoiceAddressCard);
