import React, {
    useMemo
} from "react";

import PriceFormatter from "./PriceFormatter";
import isString from "../utilities/isString";

function PriceDisplay({ amount = 1, product, stacked = false }) {
    const originalPrice = useMemo(() => {
        if(!product) {
            return null;
        }
        if(product.cartPrice) {
            return product.cartPrice.originalPriceInclVat;
        }
        if(product.originalPriceInclVat) {
            return product.originalPriceInclVat;
        }
        return product?.price;
    }, [product]);

    const currentPrice = useMemo(() => {
        if(!product) {
            return null;
        }
        if(product.cartPrice) {
            return product.cartPrice.currentPriceInclVat;
        }
        if(product.orderPrice) {
            return product.orderPrice;
        }
        if(product.discountPrice) {
            return product.discountPrice;
        }
        return product.price;
    }, [product]);

    const difference = useMemo(() => {
        const originalPriceStr = isString(originalPrice) ? originalPrice : originalPrice?.toFixed(2);
        const currentPriceStr = isString(currentPrice) ? currentPrice : currentPrice?.toFixed(2);
        return originalPriceStr !== currentPriceStr;
    }, [originalPrice, currentPrice]);

    if(!product) {
        return null;
    }

    return (
        <React.Fragment>
            { difference && (
                <React.Fragment>
                    <s
                        className={ "text-muted" + (stacked ? "" : " mr-1") }
                        style={{ fontSize: "0.9rem" }}
                    >
                        <PriceFormatter price={ amount * originalPrice }/>
                    </s>
                    { stacked && (
                        <br/>
                    )}
                </React.Fragment>
            )}
            <b>
                <PriceFormatter price={ amount * currentPrice }/>
            </b>
        </React.Fragment>
    )
}

export default React.memo(PriceDisplay);
