import React from "react";

function ProductPageStickyBottomBar({ children }) {
    return (
        <div className="product-page-sticky-bottom-bar">
            <div className="container-fluid">
                { children }
            </div>
        </div>
    );
}

export default React.memo(ProductPageStickyBottomBar);
