import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import RentalContext from "../../../context/internal/RentalManager";

function RentalInfoInvoiceAddressCard() {
    const rental = useContext(RentalContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="invoiceAddress" />
                </h4>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="invoiceFirstName">
                                <Trans i18nKey="firstName" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceFirstName"
                                required
                                value={ rental.rentalInfo.invoiceFirstName }
                                onChange={ (event) => rental.updateRentalInfo({ invoiceFirstName: event.target.value }) }
                                autoComplete="billing given-name"
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="invoiceLastName">
                                <Trans i18nKey="lastName" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceLastName"
                                required
                                value={ rental.rentalInfo.invoiceLastName }
                                onChange={ (event) => rental.updateRentalInfo({ invoiceLastName: event.target.value }) }
                                autoComplete="billing family-name"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="invoiceStreet">
                                <Trans i18nKey="street" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceStreet"
                                required
                                value={ rental.rentalInfo.invoiceStreet }
                                onChange={ (event) => rental.updateRentalInfo({ invoiceStreet: event.target.value }) }
                                autoComplete="billing street-address"
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="invoiceHouseNumber">
                                <Trans i18nKey="houseNumber" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceHouseNumber"
                                required
                                value={ rental.rentalInfo.invoiceHouseNumber }
                                onChange={ (event) => rental.updateRentalInfo({ invoiceHouseNumber: event.target.value }) }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="invoicePostalCode">
                                <Trans i18nKey="postalCode" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoicePostalCode"
                                required
                                value={ rental.rentalInfo.invoicePostalCode }
                                onChange={ (event) => rental.updateRentalInfo({ invoicePostalCode: event.target.value }) }
                                autoComplete="billing postal-code"
                            />
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="invoiceCity">
                                <Trans i18nKey="city" />
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="invoiceCity"
                                required
                                value={ rental.rentalInfo.invoiceCity }
                                onChange={ (event) => rental.updateRentalInfo({ invoiceCity: event.target.value }) }
                                autoComplete="billing address-level2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(RentalInfoInvoiceAddressCard);
