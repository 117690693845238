import React, {
    useCallback,
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link,
    useHistory,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import RentalContext from "../../context/internal/RentalManager";
import RentalInfoAccountCard from "./components/RentalInfoAccountCard";
import RentalInfoContactCard from "./components/RentalInfoContactCard";
import RentalInfoInvoiceAddressCard from "./components/RentalInfoInvoiceAddressCard";
import RentalInfoCustomerCard from "./components/RentalInfoCustomerCard";

function RentalInfoPageContent({ match }) {
    const productId = useMemo(() => {
        return parseInt(match.params.productId);
    }, [match]);

    const rental = useContext(RentalContext);
    const history = useHistory();
    const [error, setError] = useState(null);

    const onError = useCallback((newError) => {
        setError(newError);
        window.scroll({ top: 0, behavior: "smooth" });
    }, []);
    const toNextStep = useCallback(() => {
        if(!rental.isRentalInfoValid()) {
            onError("Niet alle velden zijn correct ingevuld!");
            return;
        }
        history.push("/rental-order/" + productId + "/paymentmethod");
    }, [rental, onError, history, productId]);

    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="product"/> } to={ `/rental/${productId}` } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="date"/> } to={ `/rental-order/${productId}/date` } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to={ `/rental-order/${productId}/data` } active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to={ `/rental-order/${productId}/paymentmethod` }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to={ `/rental-order/${productId}/overview` }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>

            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}

            <RentalInfoAccountCard/>

            <RentalInfoCustomerCard/>

            <RentalInfoInvoiceAddressCard/>

            <RentalInfoContactCard/>

            <div className="card mb-3">
                <div className="card-body">
                    <Link to={ "/rental-order/" + productId + "/date" } className="btn btn-link">
                        <i className="fas fa-chevron-left mr-2"/>
                        <Trans i18nKey="previousStep"/>
                    </Link>
                    <div className="float-right">
                        <Button variant="success" onClick={ toNextStep }>
                            <Trans i18nKey="nextStep"/>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(withRouter(RentalInfoPageContent));
