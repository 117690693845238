import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import OrderContext from "../../../context/internal/OrderManager";

function DeliveryInfoCustomerCard() {
    const order = useContext(OrderContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4>
                    <Trans i18nKey="customer" />
                </h4>
                <div className="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        className="custom-control-input"
                        id="private"
                        name="company"
                        checked={ !order.deliveryInfo.company }
                        onChange={ (event) => order.updateDeliveryInfo({ company: !event.target.checked }) }
                    />
                    <label className="custom-control-label" htmlFor="private">
                        <Trans i18nKey="customerPersonal" />
                    </label>
                </div>
                <div className={ "custom-control custom-radio custom-control-inline" + (order.deliveryInfo.company ? " mb-3" : "") }>
                    <input
                        type="radio"
                        className="custom-control-input"
                        id="company"
                        name="company"
                        checked={ order.deliveryInfo.company }
                        onChange={ (event) => order.updateDeliveryInfo({ company: event.target.checked }) }
                    />
                    <label className="custom-control-label" htmlFor="company">
                        <Trans i18nKey="customerCompany" />
                    </label>
                </div>
                { order.deliveryInfo.company && (
                    <div className="form-group mb-0">
                        <label htmlFor="companyName">
                            <Trans i18nKey="companyName" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            required
                            value={ order.deliveryInfo.companyName }
                            onChange={ (event) => order.updateDeliveryInfo({ companyName: event.target.value }) }
                            autoComplete="organization"
                        />
                    </div>
                ) }
            </div>
        </div>
    );
}

export default React.memo(DeliveryInfoCustomerCard);
