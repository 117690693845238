import React from "react";
import {
    Link
} from "react-router-dom";

function Step({ title, to = "/", complete = false, active = false}) {
    let className = "col step";
    if(complete) {
        className += " complete";
    } else if(active) {
        className += " active";
    } else {
        className += " disabled";
    }
    return (
        <div className={ className }>
            { title && (
                <div className="text-center step-title">
                    { title }
                </div>
            )}
            <div className="progress">
                <div className="progress-bar"/>
            </div>
            <Link to={ to } className="step-dot"/>
        </div>
    );
}
function SteppedProgressBar(props) {
    return (
        <div className="row progressbar-stepped">
            { props.children }
        </div>
    );
}

SteppedProgressBar.Step = Step;

export default SteppedProgressBar;
