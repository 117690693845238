import {
    useMemo
} from "react";

import {
    SHOPCRATE_LANGUAGE
} from "../ShopCrateLocalization";
import isString from "../utilities/isString";

function PriceFormatter({ price }) {
    const priceFloat = useMemo(() => {
        if(!isString(price)) {
            return price;
        }
        return parseFloat(price);
    }, [price]);

    return new Intl.NumberFormat(SHOPCRATE_LANGUAGE, { style: "currency", currency: "EUR" }).format(priceFloat);
}

export default PriceFormatter;
