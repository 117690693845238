import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";

import OrderContext from "../../context/internal/OrderManager";
import ShopContext from "../../context/internal/ShopManager";
import Loading from "../Loading";
import SelectionCard from "../order/SelectionCard";

function CartFulfillmentMethodCard() {
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);
    const [fulfillmentMethods, setFulfillmentMethods] = useState(null);

    useEffect(() => {
        setFulfillmentMethods(order.getFulfillmentMethods());
    }, [order]);
    const setFulfillmentMethod = useCallback((fulfillmentMethod) => {
        order.updateDeliveryInfo({
            fulfillmentMethod: fulfillmentMethod.value
        });
    }, [order]);

    if(!shop.shop?.orderPickupEnabled) {
        return null;
    }
    return (
        <div className="card mb-2">
            <div className="card-body">
                { !fulfillmentMethods ? (
                    <Loading/>
                ) : (
                    <div className="row">
                        { fulfillmentMethods.map((fulfillmentMethod, index) => (
                            <div
                                key={ index }
                                className="col"
                            >
                                <SelectionCard
                                    active={ order.deliveryInfo.fulfillmentMethod === fulfillmentMethod.value }
                                    onClick={ () => setFulfillmentMethod(fulfillmentMethod) }
                                >
                                    <div className="d-flex flex-column">
                                        <div>
                                            { fulfillmentMethod.icon }
                                        </div>
                                        <div>
                                            { fulfillmentMethod.title }
                                        </div>
                                    </div>
                                </SelectionCard>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(CartFulfillmentMethodCard);
