import React from "react";

import LoginPageContent from "../account/LoginPageContent";

function OrderLoginPageContent() {
    return (
        <LoginPageContent
            urlAfterLogin="/order/data"
            urlRegister="/order/register"
        />
    )
}

export default React.memo(OrderLoginPageContent);
