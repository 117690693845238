import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link,
    Redirect,
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../../context/internal/CartManager";
import OrderContext from "../../context/internal/OrderManager";
import AuthenticationContext from "../../context/internal/AuthenticationManager";
import ShopContext from "../../context/internal/ShopManager";
import Loading from "../../components/Loading";
import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import DeliveryInfoAccountCard from "./components/DeliveryInfoAccountCard";
import DeliveryInfoCustomerCard from "./components/DeliveryInfoCustomerCard";
import DeliveryInfoDeliveryAddressCard from "./components/DeliveryInfoDeliveryAddressCard";
import DeliveryInfoInvoiceAddressCard from "./components/DeliveryInfoInvoiceAddressCard";
import DeliveryInfoContactCard from "./components/DeliveryInfoContactCard";

function DeliveryInfoPageContent() {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);
    const authentication = useContext(AuthenticationContext);
    const shop = useContext(ShopContext);

    const history = useHistory();

    const [error, setError] = useState(null);

    const onError = useCallback((newError) => {
        setError(newError);
        window.scroll({ top: 0, behavior: "smooth" });
    }, []);
    const toNextStep = useCallback(() => {
        if(!order.isDeliveryInfoValid()) {
            onError(i18next.t("errorFieldInput"));
            return;
        }
        history.push("/order/paymentmethod");
    }, [order, history, onError]);
    useEffect(() => {
        if(order.countries === null) {
            order.getCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(order.countriesError) {
        return (
            <Alert variant="danger">{ order.countriesError }</Alert>
        );
    }
    const authenticationStatus = authentication.getAuthenticationStatus();
    if(cart.cart === undefined || shop.shop === null || authenticationStatus === "unknown" || order.isLoading()) {
        return (
            <Loading/>
        );
    }
    if(cart.cart === null || cart.cart.products.length <= 0) {
        return (
            <Redirect to="/cart"/>
        );
    }
    if((cart.cart.requiresAccount || shop.shop.authenticationRequiredOrders) && authenticationStatus === "nosession") {
        return (
            <Redirect to="/order/account-selection"/>
        );
    }

    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="cart"/> } to="/cart" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to="/order/data" active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to="/order/paymentmethod"/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to="/order/overview"/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>

            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}

            <DeliveryInfoAccountCard/>

            <DeliveryInfoCustomerCard/>

            <DeliveryInfoDeliveryAddressCard/>

            <DeliveryInfoInvoiceAddressCard/>

            <DeliveryInfoContactCard/>

            <div className="card mb-3">
                <div className="card-body">
                    <Link to="/cart" className="btn btn-link">
                        <i className="fas fa-chevron-left mr-2"/>
                        <Trans i18nKey="previousStep"/>
                    </Link>
                    <div className="float-right">
                        <Button variant="success" onClick={ toNextStep }>
                            <Trans i18nKey="nextStep"/>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(DeliveryInfoPageContent);
