import React, {
    useContext,
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../../context/internal/CartManager";
import OrderContext from "../../context/internal/OrderManager";
import PriceFormatter from "../PriceFormatter";
import PriceSummaryRow from "../PriceSummaryRow";

function CartPriceSummary({ className }) {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);

    const shippingPrice = useMemo(() => {
        if(!cart.cart.requiresShipping) {
            return 0;
        }
        return order.shippingPrice;
    }, [cart, order]);
    const {
        totalVat,
        totalPrice,
        totalDiscount,
        originalPrice,
    } = useMemo(() => {
        const validShippingPrice = shippingPrice ?? 0;
        const {
            totalCartPrice
        } = cart.cart;
        return {
            totalVat: totalCartPrice.vat + (validShippingPrice - validShippingPrice / 1.21),
            totalPrice: totalCartPrice.currentPriceInclVat + validShippingPrice,
            totalDiscount: totalCartPrice.discountAmountInclVat,
            originalPrice: totalCartPrice.originalPriceInclVat + validShippingPrice,
        }
    }, [cart, shippingPrice]);

    return (
        <div className={ "card" + (className ? " " + className : "") }>
            <div className="card-body">
                <PriceSummaryRow
                    title={ i18next.t("shippingCosts") }
                    className="mb-2"
                >
                    { shippingPrice === null ? (
                        <Trans i18nKey="selectCountry"/>
                    ) : shippingPrice === 0 ? (
                        <Trans i18nKey="free"/>
                    ) : (
                        <PriceFormatter price={ shippingPrice }/>
                    ) }
                </PriceSummaryRow>
                <PriceSummaryRow
                    title={ i18next.t("vat") }
                    className="mb-2 text-muted"
                >
                    <PriceFormatter price={ totalVat }/>
                </PriceSummaryRow>
                { totalDiscount > 0 && (
                    <PriceSummaryRow
                        title={ i18next.t("discount") }
                        className="mb-2 text-muted"
                    >
                        <PriceFormatter price={ totalDiscount }/>
                    </PriceSummaryRow>
                )}
                { originalPrice !== totalPrice ? (
                    <React.Fragment>
                        <PriceSummaryRow
                            title={ i18next.t("originally") }
                            className="text-danger"
                            style={{
                                textDecoration: "line-through"
                            }}
                        >
                            <PriceFormatter price={ originalPrice }/>
                        </PriceSummaryRow>
                        <PriceSummaryRow
                            title={ i18next.t("totalPrice") }
                            subtitle={ i18next.t("includingVat") }
                        >
                            <PriceFormatter price={ totalPrice }/>
                        </PriceSummaryRow>
                    </React.Fragment>
                ) : (
                    <PriceSummaryRow
                        title={ i18next.t("totalPrice") }
                        subtitle={ i18next.t("includingVat") }
                    >
                        <PriceFormatter price={ totalPrice }/>
                    </PriceSummaryRow>
                )}
            </div>
        </div>
    )
}

export default React.memo(CartPriceSummary);
