import React, {
    useCallback,
    useContext,
    useMemo,
    useState
} from "react";
import {
    Trans
} from "react-i18next";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import CartContext from "../../context/internal/CartManager";
import ShopContext from "../../context/internal/ShopManager";
import PriceFormatter from "../PriceFormatter";

function CartDiscountCodes() {
    const cartContext = useContext(CartContext);
    const shopContext = useContext(ShopContext);
    const [discountCodeInput, setDiscountCodeInput] = useState("");

    const discountCodes = useMemo(() => {
        return cartContext.cart?.discountCodes;
    }, [cartContext.cart?.discountCodes]);
    const onSubmitDiscountCode = useCallback(async (event) => {
        event.preventDefault();
        const result = await cartContext.addDiscountCode(discountCodeInput);
        if(!result) {
            return;
        }
        setDiscountCodeInput("");
    }, [cartContext, discountCodeInput]);

    if(!shopContext.shop.discountCodesEnabled) {
        return null;
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex flex-column align-items-end">
                    { cartContext.discountCodeError && (
                        <div style={ { maxWidth: "300px" } }>
                            <Alert variant="danger">{ cartContext.discountCodeError }</Alert>
                        </div>
                    ) }
                    <div
                        className={ "w-100" + (discountCodes?.length > 0 ? " mb-1" : " mb-0")}
                        style={{ maxWidth: "300px" }}
                    >
                        <form className="d-flex flex-row align-items-end" onSubmit={ onSubmitDiscountCode }>
                            <div className="form-group mb-0 w-100">
                                <label htmlFor="discountCode">
                                    <Trans i18nKey="discountCode" />
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="discountCode"
                                    value={ discountCodeInput }
                                    onChange={ (event) => setDiscountCodeInput(event.target.value) }
                                    disabled={ cartContext.loading }
                                />
                            </div>
                            <Button
                                type="submit"
                                variant="secondary"
                                className="ml-2"
                                disabled={ cartContext.loading }
                            >
                                <Trans i18nKey="add"/>
                            </Button>
                        </form>
                    </div>
                    <div className="w-100" style={ { maxWidth: "300px" } }>
                        { discountCodes?.map((discountCode, index) => (
                            <div
                                key={ discountCode.id }
                                className={ "pt-2 " + (index + 1 < discountCodes.length ? "pb-2 border-bottom" : "pb-0") }
                            >
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Trans i18nKey={ `discountCodeType-${ discountCode.type }` } />
                                        { discountCode.discountValue !== null && (
                                            <span>
                                                {" "}
                                                { discountCode.discountValue === discountCode.discountValueRemaining ? (
                                                    <PriceFormatter price={ discountCode.discountValueRemaining } />
                                                ) : (
                                                    <React.Fragment>
                                                        <PriceFormatter price={ discountCode.discountValueRemaining } />
                                                        {" van "}
                                                        <PriceFormatter price={ discountCode.discountValue } />
                                                    </React.Fragment>
                                                )}
                                            </span>
                                        ) }
                                        { discountCode.discountPercentage !== null && (
                                            <span>
                                                {" "}{ discountCode.discountPercentage * 100 }%
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex-grow-1" />
                                    <div className="text-muted">
                                        { discountCode.code }
                                    </div>
                                    <OverlayTrigger overlay={
                                        <Tooltip id="remove-discountCode">
                                            <Trans i18nKey="removeDiscountCode"/>
                                        </Tooltip>
                                    }>
                                        <Button
                                            variant="link"
                                            size="sm"
                                            className="py-0 ml-2 text-muted"
                                            onClick={ () => cartContext.removeDiscountCode(discountCode.id) }
                                            disabled={ cartContext.loading }
                                        >
                                            <i className="fas fa-times" />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartDiscountCodes;
