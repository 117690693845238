import React, {
    useContext,
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";

import OrderContext from "../../../context/internal/OrderManager";
import ShopContext from "../../../context/internal/ShopManager";

function OrderOverviewFulfillmentMethodCard() {
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);

    const fulfillmentMethod = useMemo(() => {
        const fulfillmentMethods = order.getFulfillmentMethods();
        return fulfillmentMethods?.find((fulfillmentMethod) => {
            return fulfillmentMethod.value === order.deliveryInfo.fulfillmentMethod;
        });
    }, [order]);

    if(!shop.shop?.orderPickupEnabled) {
        return null;
    }
    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    <Trans i18nKey="order" />
                    <Link to="/cart" className="btn btn-link btn-sm">
                        <Trans i18nKey="edit" />
                    </Link>
                </h5>
                <div className="d-flex flex-row">
                    <div>
                        { fulfillmentMethod.icon }
                    </div>
                    <div className="ml-2">
                        { fulfillmentMethod.title }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(OrderOverviewFulfillmentMethodCard);
