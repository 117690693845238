import React from "react";

import RegisterPageContent from "../account/RegisterPageContent";

function OrderRegisterPageContent() {
    return (
        <RegisterPageContent
            urlAfterRegister="/order/data"
            urlLogin="/order/login"
        />
    )
}

export default React.memo(OrderRegisterPageContent);
