import React from "react";
import i18next from "i18next";

import shopcrateApi from "../../ShopCrateAPI";
import {
    withShopContext
} from "./ShopManager";

const CartContext = React.createContext(null);

export class CartManagerInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: undefined,
            cartToken: undefined,
            loading: false,
            error: null,

            discountCodeError: null,

            refreshCart: this.getCart.bind(this),
            addProductToCart: this.addProductToCart.bind(this),
            setCartProductCount: this.setCartProductCount.bind(this),
            removeProductFromCart: this.removeProductFromCart.bind(this),

            addDiscountCode: this.addDiscountCode.bind(this),
            removeDiscountCode: this.removeDiscountCode.bind(this),
        }
    }

    componentDidMount() {
        this.getCart();
    }

    handleCartAPIResponse(cart, cartToken) {
        if(cart.paidOrder) {
            this.clearCart();
            return;
        }
        this.setState({
            cart,
            cartToken
        });
        localStorage.setItem("cartToken", cartToken);
    }

    clearCart() {
        this.setState({ cart: null, cartToken: null });
        localStorage.removeItem("cartToken");
    }

    getCartToken() {
        const token = localStorage.getItem("cartToken");
        if(token === null) {
            return undefined;
        }
        return token;
    }

    getAxios() {
        const {
            shopApi
        } = this.props.shopContext;
        return shopApi ? shopApi : shopcrateApi;
    }

    getCart() {
        const token = this.getCartToken();
        if(!token) {
            this.clearCart();
            return;
        }
        this.setState({ loading: true, error: null });
        this.getAxios().post("/getCart", {
            cartToken: token
        })
            .then((response) => {
                if(response.data.valid) {
                    this.handleCartAPIResponse(response.data.cart, response.data.token);
                } else {
                    this.setState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: i18next.t("errorGeneral") });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    async addProductToCart(productId, count) {
        return await this.handleCartMutation("/addProductToCart", {
            productId,
            count
        });
    }

    async setCartProductCount(productId, count) {
        return await this.handleCartMutation("/setCartProductCount", {
            productId,
            count
        });
    }

    async removeProductFromCart(productId) {
        return await this.handleCartMutation("/removeProductFromCart", {
            productId
        });
    }

    async handleCartMutation(url, options) {
        const token = this.getCartToken();
        this.setState({ loading: true, error: null });
        try {
            const response = await this.getAxios().post(url, {
                cartToken: token,
                ...options
            });
            if(!response.data.valid) {
                this.setState({ loading: false, error: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                return false;
            }
            this.handleCartAPIResponse(response.data.cart, response.data.token);
        } catch(error) {
            console.error(error);
            this.setState({ loading: false, error: i18next.t("errorGeneral") });
            return false;
        }
        this.setState({ loading: false });
        return true;
    }

    async addDiscountCode(discountCode) {
        return await this.handleDiscountCodeMutation("/addDiscountCodeToCart", {
            discountCode
        });
    }

    async removeDiscountCode(discountCodeId) {
        return await this.handleDiscountCodeMutation("/removeDiscountCodeFromCart", {
            discountCodeId
        });
    }

    async handleDiscountCodeMutation(url, options) {
        const token = this.getCartToken();
        this.setState({ loading: true, discountCodeError: null });
        try {
            const response = await this.getAxios().post(url, {
                cartToken: token,
                ...options,
            });
            if(!response.data.valid) {
                const errorMessage = this.getDiscountCodeErrorMessage(response.data.error);
                this.setState({ loading: false, discountCodeError: errorMessage });
                return false;
            }
            this.handleCartAPIResponse(response.data.cart, response.data.token);
        } catch(requestError) {
            console.error(requestError);
            this.setState({
                loading: false,
                discountCodeError: this.getDiscountCodeErrorMessage(requestError.response?.data?.error)
            });
            return false;
        }
        this.setState({ loading: false });
        return true;
    }

    getDiscountCodeErrorMessage(error) {
        switch(error) {
        case "INVALID_DISCOUNT_CODE":
            return i18next.t("errorInvalidDiscountCode");
        case "DISCOUNT_CODE_NOT_APPLICABLE":
            return i18next.t("errorDiscountCodeNotApplicable");
        case "DISCOUNT_CODE_ALREADY_IN_CART":
            return i18next.t("errorDiscountCodeAlreadyInCart");
        case "DOESNT_EXIST":
            return i18next.t("errorDiscountCodeDoesntExist");
        default:
            return i18next.t("errorGeneral") + ` (${ error })`;
        }
    }

    render() {
        return (
            <CartContext.Provider value={ this.state }>
                {this.props.children}
            </CartContext.Provider>
        )
    }
}

export function withCartContext(Component) {
    return function contextComponent(props) {
        return (
            <CartContext.Consumer>
                {context => <Component {...props} cartContext={context} />}
            </CartContext.Consumer>
        )
    }
}

export const CartManager = withShopContext(CartManagerInternal);

export default CartContext;
