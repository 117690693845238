import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";

import OrderContext from "../../../context/internal/OrderManager";

function OrderOverviewContactInfoCard() {
    const order = useContext(OrderContext);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    <Trans i18nKey="contactInformation" />
                    <Link to="/order/data" className="btn btn-link btn-sm">
                        <Trans i18nKey="edit" />
                    </Link>
                </h5>
                <p className="card-text">
                    { order.deliveryInfo.email }
                    <br />
                    { order.deliveryInfo.phoneNumber }
                </p>
            </div>
        </div>
    );
}

export default React.memo(OrderOverviewContactInfoCard);
