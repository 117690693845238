import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";

import PaymentMethodContext from "../../../context/internal/PaymentMethodManager";

function OrderOverviewPaymentMethodCard() {
    const paymentMethodContext = useContext(PaymentMethodContext);

    const paymentMethod = !paymentMethodContext.paymentMethods ? null : paymentMethodContext.paymentMethods.find((paymentMethod) => {
        return paymentMethod.id === paymentMethodContext.paymentMethod;
    });

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    <Trans i18nKey="paymentMethod" />
                    <Link to="/order/paymentmethod" className="btn btn-link btn-sm">
                        <Trans i18nKey="edit" />
                    </Link>
                </h5>
                <div className="d-flex">
                    <img src={ paymentMethod.image.svg } height="24px" alt="Icon" />
                    <span className="d-inline ml-2">
                        { paymentMethod.description }
                    </span>
                </div>
            </div>
        </div>
    );
}

export default React.memo(OrderOverviewPaymentMethodCard);
