import React, {
    useEffect,
    useRef,
    useState
} from "react";
import ReactPlayer from "react-player";

import useWindowSize from "../hooks/WindowSize";

function VideoPlayer({ url, maxWidth = 640, resizeWindowHeight = false }) {
    const containerRef = useRef();
    const windowSize = useWindowSize();

    const [biggestWindowHeight, setBiggestWindowHeight] = useState(null);
    const [width, setWidth] = useState(550);
    const [height, setHeight] = useState(550 / 16 * 9);

    // Select the biggest window height that is seen on the device. This is used to make sure the video vertically fits on mobile displays.
    // Saving the biggest size prevents the video from resizing when the address bar appears again.
    useEffect(() => {
        if(!resizeWindowHeight) {
            setBiggestWindowHeight(null);
            return;
        }
        if(windowSize.height > windowSize.width) {
            setBiggestWindowHeight(null);
            return;
        }
        setBiggestWindowHeight((prevBiggestWindowHeight) => {
            if(prevBiggestWindowHeight === null || windowSize.height > prevBiggestWindowHeight) {
                return windowSize.height;
            }
            return prevBiggestWindowHeight;
        });
    }, [windowSize, resizeWindowHeight]);

    useEffect(() => {
        if(!containerRef.current) {
            return;
        }
        const heightMargin = 70;

        let calculatedWidth = Math.min(maxWidth, containerRef.current.offsetWidth);
        let calculatedHeight = calculatedWidth / 16 * 9;

        // If the video is too big vertically, resize it to fit the screen, mostly on mobile displays.
        if(biggestWindowHeight !== null && calculatedHeight + heightMargin >= biggestWindowHeight) {
            calculatedHeight = biggestWindowHeight - heightMargin;
            calculatedWidth = calculatedHeight / 9 * 16;
        }

        setWidth(calculatedWidth);
        setHeight(calculatedHeight);
    }, [containerRef, windowSize, maxWidth, biggestWindowHeight]);

    return (
        <div ref={ containerRef }>
            <ReactPlayer
                url={ url }
                controls
                width={ width }
                height={ height }
                config={{
                    vimeo: {
                        playerOptions: {
                            playsinline: 1
                        }
                    }
                }}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            />
        </div>
    );
}

export default React.memo(VideoPlayer);
